import React, { useState, useEffect, useCallback } from 'react';
import { useAppStore } from './../stores/useAppStore';
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
//import { useSessionStore } from './../stores/useSessionStore';
//import DroppButton from './../buttons/dropp';
import Terms from '../boilerplate/terms';
import * as api from '../../api/api';
import { delay } from '../../shared/fn';
import { LBL_SHARD
    , LBL_TERMS_CONDITIONS
    , LBL_REALM, LBL_NUM
    , LBL_USE_OF_SITE_TEXT
    , LBL_REQUIRED_WALLET
    , LBL_DROPP
    , LBL_GET_ACCESS_TEXT
} from '../../shared/constants';
import './_forms.scss';

import {
    useQueries,
} from '@tanstack/react-query';

const AddWallet = (props) => { 
    const { accessCode } = props;
    const [formMode, setFormMode] = useState('edit');
    const [responseMessage, setResponseMessage] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [displayBasic, setDisplayBasic] = useState(false);
    const [spinnerVisible, setSpinnerVisible] = useState(false);
    const [shard, setShard] = useState(0);
    const [realm, setRealm] = useState(0);
    const [num, setNum] = useState('');
    const [vipAccessData, setVipAccessData] = useState(null);
    const [perDayFee, setPerDayFee] = useState();
    //const [isInstalled, setIsInstalled] = useState(false);
    // const [xxx, setXxx] = useState();

    const [appStore] = useAppStore();
    const { closeDialog } = props;

    const getVipAccess = useCallback(async (qk) => {
        const code = qk.queryKey[1];
        const data=await api.getVipAccess(code)
            .then(function (response) {
                return response;
            });
        return data;
    }, []);

    const responses = useQueries({
        queries: [
           { 
                queryKey: ['get-VipAccess-data', accessCode], 
                queryFn: getVipAccess, 
                enabled: accessCode !== null 
            },
        ],
    });

    const handleShardChange =(e) => {
        if(!e) {
            e = 0;
        }

        setShard(e);
    }
    
    const handleRealmChange =(e) => {
        if(!e) {
            e = 0;
        }

        setRealm(e);
    }

    const handleNumChange =(e) => {
        if(!e) {
            e = 0;
        }

        setNum(e);
    }

    const dialogFuncMap = {
        'displayBasic': setDisplayBasic
    }

    const onClick = (name) => {
        dialogFuncMap[`${name}`](true);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const handleBackClick = () => {
        setFormMode('edit');
        setDisabled(false);
    }

    const handleReviewClick = (e, formMode) => {
        if(formMode ==='edit') {
            setFormMode('review');
        }
        else {
            submit();
        }
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="Close" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" autoFocus/>
            </div>
        );
    }

    const handleVipAccessResponse = useCallback((data) => {
        //console.log('handleVipAccessResponse', data)
        setVipAccessData(data);
    }, []);

    

    useEffect(() => {
        const setNumSelected = async() => {
            await delay(1000);            
            document.getElementById('num').addEventListener('focus', function () {
                this.select();
            });
            document.getElementById('num').focus();
        }
        
        const showExpiredText = async() => {
            await delay(1000);
            const expTextDiv = document.getElementById("ExpiredText");
            
            if(expTextDiv) {
                if(vipAccessData?.statusid===2) {
                    expTextDiv.classList.remove('hidden')
                }
            }
        }

        if(responses[0].status==="success") {
            handleVipAccessResponse(responses[0].data);
        }

        setPerDayFee(appStore.appSettings?.find(x => x.settingname==='per_day_fee')?.settingvalue)

        setNumSelected();
        showExpiredText();
    }, [ responses, handleVipAccessResponse, appStore, vipAccessData]);

    //useEffect(() => {
        
    //}, [ ]);

    const submit = async () => {        
        setSpinnerVisible(true);

        try {
            if(vipAccessData?.statusid===1) {
                const response = await api.updateUserVipAccess(vipAccessData?.useraccessid, shard, realm, num);
                console.log('response', response)
                if(response?.data?.ret[0].userwalletid > 0) {
                    setResponseMessage(`Your Account Id has been successfully saved! To begin your extended access, click the '${LBL_GET_ACCESS_TEXT}' button. A one-time fee of $${perDayFee} will apply.`)
                }

                setSpinnerVisible(false);
                return;
            }
            
            closeDialog(false);
        }
        catch(err) {
            setSpinnerVisible(false);
            setFormMode('error');

            if(!err) {
                closeDialog();
            }
        }
    };  

    return (
        <div style={{width: '100%'}}>
            {/* {vipAccessData?.accesscode} */}
            <div id="ExpiredText" className={`form ${formMode}-mode p-fluid p-4 hidden`}>
                Sorry, this access code has expired.
            </div>

            <div className={`form ${formMode}-mode p-fluid pt-1`}>
                <div className={`${vipAccessData?.statusid===1 ? 'mt-4' : 'hidden'}`} style={{display: 'inline-block'}}>
                    <ProgressSpinner 
                        style={{position: 'absolute', top: '50%', width: '50%', height: '10%', transform: 'translate(-50%, -50%)'}}
                        className={`${spinnerVisible ? '' : 'hidden'}`}
                    />

                    <div className={`form-message ${responseMessage ? '' : 'hidden'}`} style={{lineHeight: '1.5rem', fontSize: '1.25rem'}}>
                        {responseMessage}
                    </div>

                    <div className={`form-message ${formMode==='edit' ? 'edit-message' : 'hidden'}`} style={{lineHeight: '1.25rem'}}>                            
                        We ask for your Dropp Account Id to associate it with extended access privileges, allowing us to recognize your visit so you can enjoy 
                        uninterrupted extended access each time. Please note, providing your Account Id does not involve sharing any personally identifying 
                        information.
                        <div className={`pt-2`}>
                            You can enter your Account Id after <Link to="https://dropp.cc/get-dropp-app" target="_blank" rel="noopener noreferrer" className={`italic link`}>downloading the {LBL_DROPP} app</Link>.
                        </div>
                        <div className={`mt-2`} style={{fontWeight:'500', fontSize:'1.1rem', color:'#fe8d59'}}>
                            * {LBL_REQUIRED_WALLET}&nbsp;desktop&nbsp;{LBL_DROPP}&nbsp;extension.
                        </div>
                    </div>
                    
                    <div className={`form-message ${formMode==='review' && !responseMessage ? 'review-message' : 'hidden'}`}>                            
                        Please review and click 'Submit' to add your Dropp Account Id.
                    </div>

                    <div className={`flex justify-content-center gap-3 pt-6 mt-7 ${formMode==='review' ? 'mt-0' : ''}`}>
                        <div className="field shard">
                            <span className="">
                                <label 
                                    htmlFor="shard" 
                                    className={`${formMode} ${formMode==='edit' ? '' : (responseMessage ? 'hidden' : '')}`}
                                    style={{}}
                                >
                                    {LBL_SHARD}: <span className='asterisk'>*</span>
                                </label>&nbsp;
                                
                                <InputNumber
                                    inputId="shard" 
                                    value={shard}
                                    min={0}
                                    required
                                    allowEmpty={false}
                                    className={`${formMode==='edit' ? '' : 'hidden'}`}
                                    onValueChange={(e) => handleShardChange(e.value)} 
                                    useGrouping={false}
                                    style={{height: '2.5rem', width: '10rem'}}
                                />
                                <span className={`review ${formMode==='edit' || responseMessage ? 'hidden' : ''}`}>{shard}</span>
                            </span>
                        </div>
                        
                        <div className="field realm">
                            <span className="">
                                <label 
                                    htmlFor="realm" 
                                    className={`${formMode} ${formMode==='edit' ? '' : (responseMessage ? 'hidden' : '')}`}
                                    style={{}}
                                >
                                    {LBL_REALM}: <span className='asterisk'>*</span>
                                </label>&nbsp;
                                
                                <InputNumber 
                                    inputId="realm" 
                                    value={realm}
                                    min={0}
                                    required
                                    allowEmpty={false}
                                    className={`${formMode==='edit' ? '' : 'hidden'}`}
                                    onValueChange={(e) => handleRealmChange(e.value)} 
                                    useGrouping={false} 
                                    style={{height: '2.5rem', width: '10rem'}}
                                />
                                <span className={`review ${formMode==='edit' || responseMessage ? 'hidden' : ''}`}>{realm}</span>
                            </span>
                        </div>

                        <div className="field num">
                            <span className="">
                                <label 
                                    htmlFor="realm" 
                                    className={`${formMode} ${formMode==='edit' ? '' : (responseMessage ? 'hidden' : '')}`}
                                    style={{}}
                                >
                                    {LBL_NUM}: <span className='asterisk'>*</span>
                                </label>&nbsp;
                                
                                <InputNumber 
                                    inputId="num"
                                    value={num}
                                    min={0}
                                    autoFocus
                                    required
                                    allowEmpty={false}
                                    className={`${formMode==='edit' ? '' : 'hidden'}`}
                                    onValueChange={(e) => handleNumChange(e.value)} 
                                    useGrouping={false} 
                                    style={{height: '2.5rem', width: '10rem'}}
                                />
                                <span className={`review ${formMode==='edit' || responseMessage ? 'hidden' : ''}`}>{num}</span>
                            </span>
                        </div>
                    </div>                

                    <div className={`flex justify-content-center gap-3 mt-2 ${formMode==='review' ? '' : ''}`}>                    
                        <div className={`field-checkbox ${formMode==='review' ? 'hidden' : ''}`}>
                            {LBL_USE_OF_SITE_TEXT}&nbsp;<Link onClick={() => onClick('displayBasic')} className={`link`}>{LBL_TERMS_CONDITIONS}</Link>
                            <label 
                                htmlFor="accept" 
                                className={``}>
                            </label>
                        </div>
                    </div>
                </div>

                <div className={`faux-form-footer `}>
                    <Button 
                        type="button" 
                        label={`${vipAccessData?.statusid !==1 || responseMessage ? 'Close' : 'Cancel'}`}
                        onClick={(e) => closeDialog()} 
                        className="mt-0 p-button-secondary"
                        disabled = {false}
                        visible={true}
                    />
                    
                    <Button 
                        type="button" 
                        label="Back" 
                        onClick={(e) => handleBackClick('edit')} 
                        className={`mt-0 p-button-secondary`}
                        disabled = {formMode==='edit' ? true : false}
                        visible={formMode==='edit' ? false : (responseMessage ? false : true)}
                    />
                    
                    <Button 
                        type="button" 
                        label={formMode==='edit' ? "Review" : "Submit"} 
                        onClick={(e) => handleReviewClick(e, formMode) } 
                        className={`mt-0 p-button-secondary ${vipAccessData?.statusid!==1 ? 'hidden' : ''}`}
                        disabled = {(formMode==='edit' ? false : disabled)}
                        visible={formMode==='edit' ? true : (responseMessage ? false : true)}
                    />
                </div>                        
            </div>

            <Dialog 
                header={LBL_TERMS_CONDITIONS}
                visible={displayBasic} 
                style={{ width: '60vw' }} 
                footer={renderFooter('displayBasic')} 
                onHide={() => onHide('displayBasic')}
            >
                <div><Terms /></div>
            </Dialog>
        </div>

    );
  }

  export default AddWallet